jQuery(function() {
    $('.js-load-more').on('click', function(){
        NProgress.start();
        var thisButton = $(this);
        var parent = thisButton.closest('.c-career-holder');
        var url = parent.find('.c-career-holder__grid').data('url');
        var totalItems = parent.find('.c-career-holder__grid').data('total');
        var currenTotaltItems = parent.find('.c-career-holder__grid .c-career-holder__grid-item').length;

        $.ajax({
            method: 'GET',
            url: url + 'loadMoreVacancies',
            data: {
                total: currenTotaltItems
            },
            success: function(response) {
                if(response) {
                    parent.find('.c-career-holder__grid').append(response);
                }

                if((currenTotaltItems + 3) >= totalItems) {
                    thisButton.closest('.c-career-holder__loadmore').fadeOut(200);
                }

                NProgress.done();
            },
            error: function(jqXHR, status, errorThrown) {
                console.warn(status);
                NProgress.done();
            }
        });
    });
});